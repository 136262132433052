<template>
  <div class="medical-record-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ isNew ? 'Novo' : 'Visualizar' }} Prontuário
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" v-if="fields.length">
        <div v-for="(field, idx) in fields" :key="idx" class="fields">
          <div v-if="field.field_model_type === 1">
            <b-field :label="field.label">
              <b-input
                v-if="isFiled"
                :value="field.value | date"
                type="text"
                readonly
              ></b-input>

              <b-datepicker
                v-if="!isFiled"
                v-model="field.value"
                placeholder="Selecionar"
                icon="calendar"
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
          <div v-if="field.field_model_type === 2">
            <b-field :label="field.label">
              <b-input
                v-model="field.value"
                type="text"
                :readonly="isFiled"
              ></b-input>
            </b-field>
          </div>
          <div v-if="field.field_model_type === 3">
            <b-field :label="field.label">
              <editor
                v-model="field.value"
                :api-key="editorApiKey"
                :disabled="isFiled"
              ></editor>
            </b-field>
          </div>
        </div>
        <div class="attachment">
          <app-file-picker
            label="Adicionar anexo"
            v-model="attachment"
            accept="image/*,application/pdf"
            :isMedicalRecord="true"
            @input="onAttachmentSelected"
            @fileDeleted="onAttachmentDeleted"
          >
          </app-file-picker>

          <div
            v-if="attachment"
            @click="onViewAttachmentClick"
            class="view-action"
          >
            Visualizar arquivo
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
        <b-button
          v-if="!isFiled && !viewOnly"
          @click="onSubmit"
          type="is-secondary"
          >Salvar</b-button
        >
        <b-button
          v-if="!isFiled && !viewOnly"
          @click="onToFileClick"
          type="is-primary"
        >
          Arquivar
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import MedicalRecordModelService from '@/services/medical-records.service';
import FiledRecord from '@/modals/FiledRecord.vue';
import FileMixin from '@/mixins/FileMixin';

export default {
  mixins: [FileMixin],
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    scheduleId: Number,
    patientId: Number,
    viewOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    editor: Editor,
  },
  data: () => ({
    editorApiKey: process.env.VUE_APP_TINY_MCE_KEY,
    fields: [],
    medical_record_model_id: null,
    isFiled: false,
    attachment: null,
  }),
  methods: {
    onToFileClick() {
      const showMessage = eval(localStorage.getItem('TO_FILE_MESSAGE'));

      if (showMessage)
        this.$buefy.modal.open({
          parent: this,
          component: FiledRecord,
          hasModalCard: true,
          trapFocus: true,
          width: '300',
          events: {
            close: (data) => {
              if (data) {
                localStorage.setItem('TO_FILE_MESSAGE', data.checked);
                this.isFiled = true;
                this.onSubmit();
              }
            },
          },
        });
      else {
        this.isFiled = true;
        this.onSubmit();
      }
    },
    onSubmit() {
      this.isNew && this.storeMedicalRecord();
      !this.isNew && this.updateMedicalRecord();
    },
    getRecordId() {
      MedicalRecordModelService.getRecord(this.record.id).then(({ data }) => {
        const { fields } = data;

        this.isFiled = data.filed;

        if (!fields || !fields.length) return [];

        this.attachment = data.attachment ?? '';

        this.fields = fields.map((field) => {
          return {
            id: field.id,
            field_model_id: field.field_model_id,
            field_model_type: field.field_model.field_model_type,
            label: field.field_model.label,
            value:
              field.field_model.field_model_type === 1
                ? new Date(field.value)
                : field.value,
          };
        });
      });
    },
    getRecordModel() {
      MedicalRecordModelService.getRecordModel().then(({ data }) => {
        const { field_models, id } = data;

        if (!field_models || !field_models.length) {
          this.$emit('close', false);
          this.$router.push('/medical-records');
          this.$buefy.snackbar.open(
            'Por favor, cadastre um modelo de prontuário!'
          );
        }

        this.medical_record_model_id = id;

        this.fields = field_models.map((model) => {
          return {
            field_model_id: model.id,
            field_model_type: model.field_model_type,
            label: model.label,
            value: model.field_model_type === 1 ? [] : '',
          };
        });
      });
    },
    loadMedicalRecord() {
      this.isNew && this.getRecordModel();
      !this.isNew && this.getRecordId();
    },
    async updateMedicalRecord() {
      const data = {
        fields: [...this.fields],
        filed: this.isFiled,
        patient_id: this.patientId,
        schedule_id: this.scheduleId ?? '',
        attachment: this.attachment
          ? await this.fileToBase64(this.attachment)
          : '',
        medical_record_model_id: this.medical_record_model_id,
      };

      MedicalRecordModelService.updateRecord(this.record.id, data)
        .then(() => {
          this.$buefy.snackbar.open('Prontuário atualizado com sucesso.');
          this.$emit('close', true);
        })
        .catch(() => {
          this.$buefy.snackbar.open(
            'Erro inesperado ao atualizar o prontuário.'
          );
        });
    },
    async storeMedicalRecord() {
      const data = {
        fields: [...this.fields],
        filed: this.isFiled,
        patient_id: this.patientId,
        schedule_id: this.scheduleId ?? '',
        attachment: this.attachment
          ? await this.fileToBase64(this.attachment)
          : '',
        medical_record_model_id: this.medical_record_model_id,
      };

      MedicalRecordModelService.storeRecord(data)
        .then(() => {
          this.$buefy.snackbar.open('Prontuário cadastrado com sucesso.');
          this.$emit('close', true);
        })
        .catch(() => {
          this.$buefy.snackbar.open(
            'Erro inesperado ao cadastrar o prontuário.'
          );
        });
    },
    onViewAttachmentClick() {
      if (
        typeof this.attachment == 'string' &&
        this.attachment.includes('http')
      ) {
        window.open(this.attachment);
      } else {
        const blobUrl = URL.createObjectURL(this.attachment);
        window.open(blobUrl);
      }
    },
    onAttachmentSelected(value) {
      this.attachment = value;
    },
    onAttachmentDeleted() {
      this.attachment = '';
    },
  },
  computed: {
    isNew() {
      return !this.record;
    },
  },
  mounted() {
    this.loadMedicalRecord();
    if (!localStorage.getItem('TO_FILE_MESSAGE'))
      localStorage.setItem('TO_FILE_MESSAGE', true);
  },
};
</script>

<style lang="scss" scoped>
.medical-record-modal {
  .modal-card {
    min-height: 700px;
    width: 75vw;
  }

  .fields {
    div {
      margin-bottom: 15px;
    }
  }

  .attachment {
    .view-action {
      margin-top: 10px;
      color: var(--color-secondary);

      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
