<template>
  <div class="to-file-record-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Arquivar Prontuário?</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <p class="message">
          Ao arquivar o prontuário, não poderá mais editá-lo.
        </p>
      </section>
      <footer class="modal-card-foot is-right">
        <b-checkbox class="checkbox" v-model="checked" :native-value="false"
          >Não mostrar essa mensagem novamente</b-checkbox
        >
        <b-button
          @click="$emit('close', { checked: !checked })"
          type="is-primary"
          >Arquivar</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    checked: false,
  }),
};
</script>

<style lang="scss" scoped>
.to-file-record-modal {
  .modal-card {
    width: 480px;
    .modal-card-body {
      .message {
        background: transparent;
        font-size: 1.2rem;
      }
    }
    .modal-card-foot {
      display: flex;
      justify-content: space-between;

      .checkbox {
        font-size: 0.9rem;
      }
    }
  }
}
</style>
