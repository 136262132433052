<template>
  <b-table :data="prescriptions" :loading="isLoading">
    <b-table-column v-slot="props" field="name" label="Nome">
      {{ props.row.name }}
    </b-table-column>

    <b-table-column v-slot="props" field="file_model" label="Modelo de receita">
      {{ props.row.file_model_title }}
    </b-table-column>

    <b-table-column v-slot="props" field="by_memed" label="Gerado pela Memed?">
      <b-icon
        :icon="props.row.by_memed ? 'thumbs-up' : 'thumbs-down'"
        :type="props.row.by_memed ? 'is-memed' : 'is-dark'"
      ></b-icon>
    </b-table-column>

    <b-table-column v-slot="props" field="date" label="Criado em">
      {{ props.row.created_at | date }}
    </b-table-column>

    <b-table-column v-slot="props" label="Opções" width="150">
      <b-tooltip label="Baixar receita" position="is-left">
        <b-button
          @click="downloadPrescription(props.row.id)"
          type="is-secondary"
          size="is-small"
          icon-right="print"
        />
      </b-tooltip>
      <b-tooltip
        label="Enviar receita para o e-mail do paciente"
        position="is-left"
      >
        <b-button
          @click="sendPrescriptionToPatient(props.row.id)"
          type="is-secondary"
          size="is-small"
          icon-right="envelope"
        />
      </b-tooltip>
      <b-tooltip label="Visualizar" position="is-left">
        <b-button
          @click="openPrescription(props.row)"
          type="is-secondary"
          size="is-small"
          icon-right="eye"
          :loading="viewOption"
        />
      </b-tooltip>
    </b-table-column>

    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="frown" size="is-large"></b-icon>
          </p>
          <p>Nenhum registro encontrado.</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import PrescriptionsService from '@/services/prescriptions.service';

export default {
  props: {
    scheduleId: Number,
    patientId: Number,
    listOnly: {
      type: Boolean,
      default: false,
    },
    viewOption: Boolean,
  },
  data: () => ({
    isLoading: false,
    prescriptions: [],
  }),
  methods: {
    openPrescription(prescription) {
      this.$emit('open', prescription);
    },
    downloadPrescription(id) {
      this.isLoading = true;
      PrescriptionsService.downloadPrescription(id)
        .then(({ data }) => {
          const { url } = data;
          const fileName = url.split('/')[url.split('/').length - 1];
          const fileLink = document.createElement('a');

          fileLink.href = url;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('target', '_blank');
          fileLink.setAttribute('style', 'display:none;');

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao fazer download de receita',
            type: 'is-danger',
          });
        })
        .finally(() => (this.isLoading = false));
    },
    sendPrescriptionToPatient(id) {
      this.isLoading = true;
      PrescriptionsService.sendPrescriptionToPatient(id)
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Receita enviada com sucesso!',
            type: 'is-success',
          });
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message:
              'Erro inesperado ao enviar receita para o e-mail do paciente',
            type: 'is-danger',
          });
        })
        .finally(() => (this.isLoading = false));
    },
    loadPrescriptions() {
      this.isLoading = true;

      this.scheduleId &&
        PrescriptionsService.get(this.scheduleId)
          .then(({ data }) => (this.prescriptions = data))
          .finally(() => (this.isLoading = false));

      this.patientId &&
        PrescriptionsService.getPatientPrescriptions(this.patientId)
          .then(({ data }) => (this.prescriptions = data))
          .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadPrescriptions();
  },
};
</script>

<style lang="scss" scoped>
.table {
  span {
    margin-right: 10px !important;
  }
}
</style>
