<template>
  <div class="medical-prescription-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ isNew ? 'Novo' : 'Visualizar' }} Receituário
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body custom-scroll">
        <b-field label="Modelos de receita">
          <b-select placeholder="Selecionar" @input="onModelSelected" expanded>
            <option :value="null">Nenhum modelo</option>
            <option v-for="model in models" :value="model" :key="model.id">
              {{ model.name }}
            </option>
          </b-select>
        </b-field>

        <div class="mt-2"></div>

        <b-field
          class="required"
          label="Tipo do modelo de receita"
          :type="errors.file_model_id ? 'is-danger' : ''"
          :message="errors.file_model_id"
        >
          <b-select
            expanded
            :disabled="!isNew"
            placeholder="Selecionar"
            v-model="selectedFileModel"
          >
            <option v-for="model in fileModels" :value="model" :key="model.id">
              {{ model.title }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="required"
          label="Nome da receita"
          :message="errors.name"
          :type="errors.name ? 'is-danger' : ''"
        >
          <b-input v-model="data.name" type="text"></b-input>
        </b-field>

        <b-field
          class="required"
          label="Data"
          :message="errors.date"
          :type="errors.date ? 'is-danger' : ''"
        >
          <b-datepicker
            v-model="data.date"
            :date-formatter="bDateFormatter"
            placeholder="Selecionar data"
            icon="calendar"
            trap-focus
          >
          </b-datepicker>
        </b-field>

        <b-field
          class="required"
          label="Descrição"
          :message="errors.prescription"
          :type="errors.prescription ? 'is-danger' : ''"
        >
          <editor
            v-model="data.prescription"
            :api-key="editorApiKey"
            :init="{ height: 320 }"
          ></editor>
        </b-field>
      </section>
      <footer class="modal-card-foot is-right">
        <b-checkbox v-model="saveAsModel" type="is-secondary"
          >Salvar como novo modelo de receita</b-checkbox
        >

        <div>
          <b-button @click="$emit('close')">Fechar</b-button>
          <b-button
            @click="onSubmitClick()"
            :loading="isSaving"
            :disabled="isSaving || !data.prescription"
            v-if="!viewOnly"
            type="is-primary"
          >
            Salvar
          </b-button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import PrescriptionsService from '@/services/prescriptions.service';
import BuefyMixin from '@/mixins/BuefyMixin';
import Editor from '@tinymce/tinymce-vue';

export default {
  mixins: [BuefyMixin],
  props: {
    prescription: {
      type: Object,
      default: () => {},
    },
    schedule: {
      type: Object,
      default: () => {},
    },
    viewOnly: {
      type: Boolean,
      default: () => false,
    },
    patientId: Number,
  },
  components: {
    editor: Editor,
  },
  data: () => ({
    editorApiKey: process.env.VUE_APP_TINY_MCE_KEY,
    isSaving: false,
    data: {
      name: null,
      date: null,
      prescription: null,
    },
    models: [],
    fileModels: [],
    selectedFileModel: null,
    sendToPatient: false,
    saveAsModel: false,
    errors: {},
  }),
  computed: {
    isNew() {
      return !this.data.schedule_id;
    },
  },
  methods: {
    onSubmitClick() {
      this.$buefy.dialog.confirm({
        message: 'Enviar receita para o e-mail do paciente?',
        confirmText: 'Sim, enviar',
        cancelText: 'Não',
        onConfirm: () => {
          this.sendToPatient = true;
          this.submit();
        },
        onCancel: () => this.submit(),
      });
    },
    submit() {
      this.errors = {};

      !this.data.id && this.save();
      this.data.id && this.update();
    },
    save() {
      this.isSaving = true;

      const data = {
        name: this.data.name,
        date: this.data.date,
        prescription: this.data.prescription,
        schedule_id: this.schedule ? this.schedule.id : '',
        patient_id: this.schedule ? this.schedule.patient_id : this.patientId,
        send_to_patient: this.sendToPatient,
        file_model_id: this.selectedFileModel ? this.selectedFileModel.id : '',
      };

      PrescriptionsService.store(data)
        .then(() => {
          this.$emit('close', true);
          this.$buefy.snackbar.open('Receituário salvo com sucesso!');
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status === 400) this.errors = data.message;
          else
            this.$buefy.snackbar.open(
              data.message ||
                'Erro ao tentar realizar o cadastro. Tente novamente mais tarde.'
            );
        })
        .finally(() => {
          this.isSaving = false;

          if (this.saveAsModel) {
            this.saveNewPrescriptionModel({
              name: data.name,
              prescription: data.prescription,
            });
          }
        });
    },
    update() {
      this.isSaving = true;

      PrescriptionsService.update(this.data.id, this.data)
        .then(() => {
          this.$emit('close', true);
          this.$buefy.snackbar.open('Receituário atualizado com sucesso!');
        })
        .catch((err) => console.log({ err }))
        .finally(() => {
          this.isSaving = false;

          if (this.saveAsModel) {
            this.saveNewPrescriptionModel({
              name: this.data.name,
              prescription: this.data.prescription,
            });
          }
        });
    },
    saveNewPrescriptionModel(model) {
      PrescriptionsService.storeModel(model).catch(() =>
        this.$buefy.snackbar.open({
          message: 'Erro ao salvar novo modelo de receita',
          type: 'is-danger',
        })
      );
    },
    getPrescriptionsModels() {
      PrescriptionsService.getModels().then(({ data: models }) => {
        this.models = models;
      });
    },
    getPrescriptionFileModels() {
      PrescriptionsService.getPrescriptionFileModels()
        .then(({ data }) => {
          this.fileModels = data;
        })
        .finally(() => {
          if (this.prescription) {
            this.selectedFileModel = this.fileModels.filter(
              (model) => model.id == this.prescription.file_model_id
            )[0];
          }
        });
    },
    onModelSelected(model) {
      if (!this.isNew)
        model = {
          prescription: model.prescription,
          name: model.name,
          schedule_id: this.data.schedule_id,
        };
      this.data = {
        ...this.data,
        prescription: model.prescription,
        name: model.name,
      };
    },
  },
  mounted() {
    if (this.prescription) {
      this.data = {
        ...this.prescription,
        date: new Date(this.prescription.date),
      };
    } else {
      this.data.date = new Date();
    }
  },
  beforeMount() {
    this.getPrescriptionsModels();
    this.getPrescriptionFileModels();
  },
};
</script>

<style lang="scss" scoped>
.medical-prescription-modal {
  .modal-card {
    width: 75vw;
  }

  .is-right {
    justify-content: space-between;
  }
}
</style>
