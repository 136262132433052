<template>
  <b-table :data="records" :loading="isLoading">
    <b-table-column v-slot="props" field="date" label="Data">
      {{ props.row.created_at | date }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Arquivado?"
      class="options"
      width="110"
    >
      <b-icon
        :icon="props.row.filed ? 'check-circle' : 'times-circle'"
        :type="props.row.filed ? 'is-secondary' : 'is-primary'"
        size="is-medium"
      ></b-icon>
    </b-table-column>
    <b-table-column v-slot="props" label="Opções" class="options" width="110">
      <b-tooltip label="Visualizar">
        <b-button
          @click="openRecord(props.row)"
          type="is-secondary"
          size="is-small"
          icon-right="eye"
        />
      </b-tooltip>
      <b-tooltip label="Deletar" v-if="!listOnly">
        <b-button
          :disabled="props.row.filed"
          @click="deleteRecord(props.row.id)"
          type="is-danger"
          size="is-small"
          icon-right="trash"
        />
      </b-tooltip>
    </b-table-column>

    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="frown" size="is-large"></b-icon>
          </p>
          <p>Nenhum registro encontrado.</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import MedicalRecordsService from '@/services/medical-records.service';

export default {
  props: {
    scheduleId: Number,
    patientId: Number,
    listOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    records: [],
  }),
  methods: {
    loadPatientRecords() {
      this.isLoading = true;

      this.scheduleId &&
        MedicalRecordsService.getScheduleRecords(this.scheduleId)
          .then(({ data }) => (this.records = data.data))
          .finally((this.isLoading = false));

      this.patientId &&
        MedicalRecordsService.getPatientMedicalRecords(this.patientId)
          .then(({ data }) => (this.records = data.data))
          .finally((this.isLoading = false));
    },
    openRecord(record) {
      this.$emit('open', record);
    },
    deleteRecord(recordId) {
      this.$emit('delete', recordId);
    },
  },
  mounted() {
    this.loadPatientRecords();
  },
};
</script>

<style lang="scss" scoped>
.table {
  span {
    margin-right: 10px !important;
  }
}
</style>
